import { BarChartRounded, FiberNewRounded, SupervisorAccountRounded, Warning } from '@mui/icons-material'
import AgencyHouseLobbyTotalsCard from './AgencyHouseLobbyTotalsCard'
import { useTranslation } from 'react-i18next'
import CashPlusIconSvg from '../../../../components/Common/SvgIcons/CashPlusIconSvg/CashPlusIconSvg'
import AgencyHouseLobbyChanges from '../AgencyHouseLobbyChanges/AgencyHouseLobbyChanges'
import { formatAsCurrency, formatAsNumber } from '../../../../utils/formatAs'
import AgencyHouseLobbyTotalsChart from './AgencyHouseLobbyTotalsChart'
import { getMonthName } from '../../../../services/getMonthName'
import { hebrew } from '../../../../i18n'
import NoChartDataSvgIcon from '../../../../components/Common/SvgIcons/NoChartDataSvgIcon/NoChartDataSvgIcon'
import { useHistory } from 'react-router'
import { agencyHouseRoutes, agencyRoutes } from '../../../../environment/urls'
import { requestDataTypes } from '../../../../types/requestDataTypes'
import MoneyShekelIconSvg from '../../../../components/Common/SvgIcons/MoneyShekelIconSvg/MoneyShekelIconSvg'
import { agencyHouseAnalyticsSubroute } from '../../../../environment/urls'
import { isDeltaAgency, isRegularOrDeltaAgency } from '../../../login/reducers/loginSelectors'
import FamilyGroupsToggle from '../../../../components/FamilyGroupsToggle/FamilyGroupsToggle'
import { useSelector } from 'react-redux'
import './AgencyHouseLobbyTotals.scss'
import { deltaCommissionViewType } from '../../../../types/deltaCommissionViewTypes'

const AgencyHouseLobbyTotalsValue = ({ value = null, warn = false, noDataText = '--' }) => (
  <div className='agency-house-lobby-totals-card-value-container'>
    <div className='agency-house-lobby-totals-card-value'>
      {value ? (
        <>
          <span>₪</span>
          {formatAsCurrency(value, { currency: '' })}
        </>
      ) : (
        <span className='agency-house-lobby-totals-card-no-data'>{noDataText}</span>
      )}
    </div>
    {warn && (
      <div className='agency-house-lobby-totals-card-value-warn'>
        <Warning />
      </div>
    )}
  </div>
)

const AgencyHouseLobbyTotals = ({ totals, totalsLoading }) => {
  const {
    t,
    i18n: { language: lng },
  } = useTranslation('agencyHouseLobby')
  const tTotals = (key, params) => t(`totals.${key}`, { ...params })
  const history = useHistory()
  const { commissionView } = useSelector(({ shared }) => shared)

  const totalsKeys = Object.keys(totals)

  const onCardClickHandler = productDataType => e => {
    e.stopPropagation()
    history.push(`${agencyHouseRoutes.analytics}${agencyHouseAnalyticsSubroute.incomes}?dataType=${productDataType}`)
  }

  const avrgCommissionChartData = () => {
    const data = []
    const labels = []
    getMainValueByKey('CommissionAverageForLastThreeMonth').forEach(v => {
      data.push(v.Value)
      const date = new Date(v.Year, v.Month - 1, 1)
      labels.push(getMonthName(date, lng, 'short'))
    })
    return { data: hebrew() ? data.reverse() : data, labels: hebrew() ? labels.reverse() : labels }
  }

  const titleSuffix = () => {
    if (!isDeltaAgency() || commissionView === deltaCommissionViewType.bothSideBySide) {
      return ''
    }
    return ' ' + tTotals(`titleSuffix${commissionView}`)
  }

  const getMainValueByKey = key => {
    if (!isDeltaAgency()) {
      return totals[key]
    }
    const subKey =
      commissionView === deltaCommissionViewType.agency || commissionView === deltaCommissionViewType.bothSideBySide
        ? 'Agency'
        : commissionView === deltaCommissionViewType.bothTogether
        ? 'Total'
        : ''

    const keyToFindWords = [...key.split(/(?=[A-Z])/).filter(Boolean), subKey].filter(Boolean)
    const totalKey = totalsKeys.find(k => {
      const totalKeyWords = k.split(/(?=[A-Z])/).filter(Boolean)
      return totalKeyWords.length === keyToFindWords.length && keyToFindWords.every(kw => totalKeyWords.includes(kw))
    })
    return totals[totalKey]
  }

  return (
    <div className='agency-house-lobby-totals'>
      <AgencyHouseLobbyTotalsCard
        icon={<MoneyShekelIconSvg />}
        name={tTotals('monthlyCommission') + titleSuffix()}
        loading={totalsLoading}
        onCardClick={onCardClickHandler(requestDataTypes.commission)}
      >
        <div className='agency-house-lobby-totals-card-double-values'>
          <div className='agency-house-lobby-totals-card-values'>
            {commissionView === deltaCommissionViewType.bothSideBySide && (
              <div className='agency-house-lobby-totals-card-heading'>
                <p>{tTotals(`titleSuffix1`)}</p>
              </div>
            )}
            <AgencyHouseLobbyTotalsValue
              value={getMainValueByKey('MonthlyCommission')?.Value}
              noDataText={tTotals('noDataText', { dataType: tTotals('monthlyCommission') })}
            />
            <AgencyHouseLobbyChanges value={getMainValueByKey('MonthlyCommission')?.Difference} />
          </div>
          {commissionView === deltaCommissionViewType.bothSideBySide && (
            <div className='agency-house-lobby-totals-card-values'>
              <div className='agency-house-lobby-totals-card-heading'>
                <p>{tTotals(`titleSuffix2`)}</p>
              </div>
              <AgencyHouseLobbyTotalsValue
                value={totals?.MonthlyCommission?.Value}
                noDataText={tTotals('noDataText', { dataType: tTotals('monthlyCommission') })}
              />
              <AgencyHouseLobbyChanges value={totals?.MonthlyCommission?.Difference} />
            </div>
          )}
        </div>
      </AgencyHouseLobbyTotalsCard>
      <AgencyHouseLobbyTotalsCard
        icon={<BarChartRounded />}
        name={tTotals('monthlyPremium')}
        loading={totalsLoading}
        onCardClick={onCardClickHandler(requestDataTypes.premium)}
      >
        <div className='agency-house-lobby-totals-card-values'>
          <AgencyHouseLobbyTotalsValue
            value={totals?.MonthlyPremium?.Value}
            noDataText={tTotals('noDataText', { dataType: tTotals('monthlyPremium') })}
          />
          <AgencyHouseLobbyChanges value={totals?.MonthlyPremium?.Difference} />
        </div>
      </AgencyHouseLobbyTotalsCard>
      <AgencyHouseLobbyTotalsCard
        icon={<CashPlusIconSvg />}
        name={tTotals('accumulation')}
        loading={totalsLoading}
        onCardClick={onCardClickHandler(requestDataTypes.accumulation)}
      >
        <div className='agency-house-lobby-totals-card-values'>
          <AgencyHouseLobbyTotalsValue
            value={totals?.MonthlyAccumulation?.Value}
            noDataText={tTotals('noDataText', { dataType: tTotals('accumulation') })}
          />
          <AgencyHouseLobbyChanges value={totals?.MonthlyAccumulation?.Difference} />
        </div>
      </AgencyHouseLobbyTotalsCard>
      <AgencyHouseLobbyTotalsCard
        icon={<FiberNewRounded />}
        name={tTotals('newPoliciesCommission') + titleSuffix()}
        loading={totalsLoading}
        {...(isRegularOrDeltaAgency() && { onCardClick: () => history.push(agencyRoutes.policies + '?type=new') })}
      >
        <div className='agency-house-lobby-totals-card-values'>
          <div className='agency-house-lobby-totals-card-double-values'>
            <div className='agency-house-lobby-totals-card-values'>
              {commissionView === deltaCommissionViewType.bothSideBySide && (
                <div className='agency-house-lobby-totals-card-heading'>
                  <p>{tTotals(`titleSuffix1`)}</p>
                </div>
              )}
              <AgencyHouseLobbyTotalsValue
                value={getMainValueByKey('NewCommission')?.Value}
                noDataText={tTotals('noDataText', { dataType: tTotals('newPoliciesCommission') })}
              />
            </div>
            {commissionView === deltaCommissionViewType.bothSideBySide && (
              <div className='agency-house-lobby-totals-card-values'>
                <div className='agency-house-lobby-totals-card-heading'>
                  <p>{tTotals(`titleSuffix2`)}</p>
                </div>
                <AgencyHouseLobbyTotalsValue
                  value={totals.NewCommission?.Value}
                  noDataText={tTotals('noDataText', { dataType: tTotals('newPoliciesCommission') })}
                />
              </div>
            )}
          </div>
          <p className='agency-house-lobby-totals-card-heading'>
            {tTotals('newPolicies', {
              policiesCount: totals.NewPoliciesCount ? formatAsNumber(totals.NewPoliciesCount) : '--',
            })}
          </p>
          <div className='agency-house-lobby-totals-card-values-splitted'>
            <div className='agency-house-lobby-totals-card-values-splitted-value'>
              <p>
                {totals.NewPoliciesFromExistingClientsCount && totals.NewPoliciesFromExistingClientsCount !== 0
                  ? tTotals('policies', { policiesCount: totals.NewPoliciesFromExistingClientsCount })
                  : '--'}
              </p>
              <p>{tTotals('fromExistingCustomers')}</p>
            </div>
            <div className='agency-house-lobby-totals-card-values-splitted-value'>
              <p>
                {totals.NewPoliciesFromNewClientsCount && totals.NewPoliciesFromNewClientsCount !== 0
                  ? tTotals('policies', { policiesCount: totals.NewPoliciesFromNewClientsCount })
                  : '--'}
              </p>
              <p>{tTotals('fromNewCustomers')}</p>
            </div>
          </div>
        </div>
      </AgencyHouseLobbyTotalsCard>
      <AgencyHouseLobbyTotalsCard
        icon={<SupervisorAccountRounded />}
        name={tTotals('avrgCustomerCommission') + titleSuffix()}
        loading={totalsLoading}
        onCardClick={onCardClickHandler(requestDataTypes.commission)}
      >
        {commissionView !== deltaCommissionViewType.bothSideBySide ? (
          <>
            <div className='agency-house-lobby-totals-card-values'>
              <AgencyHouseLobbyTotalsValue
                value={getMainValueByKey('AverageCommission')?.Value}
                noDataText={tTotals('noDataText', { dataType: tTotals('avrgCustomerCommission') })}
              />
              <AgencyHouseLobbyChanges value={getMainValueByKey('AverageCommission')?.Difference} />
            </div>
            <div className='agency-house-lobby-totals-card-chart'>
              <>
                {(isRegularOrDeltaAgency() || history.location.pathname.includes('Agency')) && (
                  <div className='agency-house-lobby-totals-card-family-toggle-wrapper'>
                    <FamilyGroupsToggle />
                  </div>
                )}
                {getMainValueByKey('CommissionAverageForLastThreeMonth').length < 2 ? (
                  <div className='no-chart-data'>
                    <NoChartDataSvgIcon />
                    <p>{tTotals('noChartData')}</p>
                  </div>
                ) : (
                  <AgencyHouseLobbyTotalsChart
                    trend={getMainValueByKey('AverageCommission')?.Difference >= 0}
                    labels={avrgCommissionChartData().labels}
                    data={avrgCommissionChartData().data}
                  />
                )}
              </>
            </div>
          </>
        ) : (
          <div className='agency-house-lobby-totals-card-double-chart-wrapper'>
            <div>
              <div className='agency-house-lobby-totals-card-values'>
                <div className='agency-house-lobby-totals-card-heading'>
                  <p>{tTotals(`titleSuffix1`)}</p>
                </div>
                <AgencyHouseLobbyTotalsValue
                  value={getMainValueByKey('AverageCommission')?.Value}
                  noDataText={tTotals('noDataText', { dataType: tTotals('avrgCustomerCommission') })}
                />
                <AgencyHouseLobbyChanges value={getMainValueByKey('AverageCommission')?.Difference} />
              </div>
              <div className='agency-house-lobby-totals-card-chart'>
                <>
                  {(isRegularOrDeltaAgency() || history.location.pathname.includes('Agency')) && (
                    <div className='agency-house-lobby-totals-card-family-toggle-wrapper'>
                      <FamilyGroupsToggle />
                    </div>
                  )}
                  {getMainValueByKey('CommissionAverageForLastThreeMonth').length < 2 ? (
                    <div className='no-chart-data'>
                      <NoChartDataSvgIcon />
                      <p>{tTotals('noChartData')}</p>
                    </div>
                  ) : (
                    <AgencyHouseLobbyTotalsChart
                      trend={getMainValueByKey('AverageCommission')?.Difference >= 0}
                      labels={avrgCommissionChartData().labels}
                      data={avrgCommissionChartData().data}
                    />
                  )}
                </>
              </div>
            </div>
            <div>
              <div className='agency-house-lobby-totals-card-values'>
                <div className='agency-house-lobby-totals-card-heading'>
                  <p>{tTotals(`titleSuffix2`)}</p>
                </div>
                <AgencyHouseLobbyTotalsValue
                  value={totals.AverageCommission?.Value}
                  noDataText={tTotals('noDataText', { dataType: tTotals('avrgCustomerCommission') })}
                />
                <AgencyHouseLobbyChanges value={totals.AverageCommission?.Difference} />
              </div>
              <div className='agency-house-lobby-totals-card-chart'>
                <>
                  {/* {(isRegularOrDeltaAgency() || history.location.pathname.includes('Agency')) && (
                    <div className='agency-house-lobby-totals-card-family-toggle-wrapper'>
                      <FamilyGroupsToggle />
                    </div>
                  )} */}
                  {totals.CommissionAverageForLastThreeMonth.length < 2 ? (
                    <div className='no-chart-data'>
                      <NoChartDataSvgIcon />
                      <p>{tTotals('noChartData')}</p>
                    </div>
                  ) : (
                    <AgencyHouseLobbyTotalsChart
                      trend={totals.AverageCommission?.Difference >= 0}
                      labels={avrgCommissionChartData().labels}
                      data={avrgCommissionChartData().data}
                    />
                  )}
                </>
              </div>
            </div>
          </div>
        )}
      </AgencyHouseLobbyTotalsCard>
    </div>
  )
}

export default AgencyHouseLobbyTotals
