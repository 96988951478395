import { useTranslation } from 'react-i18next'
import './AgencyHouseAnalyticsIncomesChangesDetailsCard.scss'
import { useEffect, useState } from 'react'
import { ExpandLessTwoTone, ExpandMoreTwoTone, ViewListRounded } from '@mui/icons-material'
import { getAgencyHouseAnalyticsPeriodDetailsRequest } from '../../../../http/requests/agencyHouseRequests'
import { useSelector } from 'react-redux'
import { getCheckedIds, getKeyByValue, lowercaseObjectKeys } from '../../../../utils/objectUtils'
import {
  analyticsIncomesExtraListType,
  analyticsIncomesList,
  analyticsIncomesListType,
  analyticsIncomesListViewType,
  getIncomesTotalsTypes,
  incomesTotalsObject,
  incomesTotalsType,
} from '../../../../types/analyticsIncomesTypes'
import { hebrew } from '../../../../i18n'
import { getMonthNameByNumber } from '../../../../services/getMonthName'
import Loader from '../../../../components/Common/Loader/Loader'
import classNames from 'classnames'
import { getRandomHexColor } from '../../../../services/getRandom'
import { getUserNameInitials } from '../../../../services/getUserNameInitials'
import { categoriesObjects } from '../../../../types/categoriesTypes'
import AgencyHouseAnalyticsHierarchyPicker from '../AgencyHouseAnalyticsHierarchyPicker/AgencyHouseAnalyticsHierarchyPicker'
import { requestDataTypes } from '../../../../types/requestDataTypes'
import { formatAsCurrency, formatAsPercent } from '../../../../utils/formatAs'
import { Fragment } from 'react-is'
import { isRegularOrDeltaAgency } from '../../../login/reducers/loginSelectors'
import { getAgencyAnalyticsPeriodDetailsRequest } from '../../../../http/requests/agencyAnalyticsRequests'
import { analyticsPeriodViewType } from '../../../../types/analyticsTypes'
import NoData from '../../../../components/Common/NoData/NoData'
import AnalyticsChangesSwitch from '../AnalyticsChangesSwitch/AnalyticsChangesSwitch'
import { cn } from '../../../../utils/stylesUtils'
import AnalyticsChangesValue from '../AnalyticsChangesValue/AnalyticsChangesValue'
import { familyGroupMode } from '../../../shared/reducers/sharedSelectors'

const initalBigAgencyPeriodHierarchy = [
  [analyticsIncomesListType.agents, analyticsIncomesListType.companies, analyticsIncomesListType.categories],
  [analyticsIncomesListType.agents, analyticsIncomesListType.categories, analyticsIncomesListType.companies],
  [analyticsIncomesListType.companies, analyticsIncomesListType.agents, analyticsIncomesListType.categories],
  [analyticsIncomesListType.companies, analyticsIncomesListType.categories, analyticsIncomesListType.agents],
  [analyticsIncomesListType.categories, analyticsIncomesListType.agents, analyticsIncomesListType.companies],
  [analyticsIncomesListType.categories, analyticsIncomesListType.companies, analyticsIncomesListType.agents],
]

const initalRegularAgencyPeriodHierarchy = [
  [analyticsIncomesListType.companies, analyticsIncomesListType.categories],
  [analyticsIncomesListType.categories, analyticsIncomesListType.companies],
]

const AgencyHouseAnalyticsIncomesChangesDetailsCard = ({
  item,
  closeCard,
  dataType,
  fromNewPolicies,
  listViewType,
}) => {
  const { selectedDates, companies, categories, selectedAgencies, periodViewType } = useSelector(
    ({ agencyHouseAnalytics }) => agencyHouseAnalytics
  )
  const calculateByFamilyGroups = familyGroupMode()

  const [details, setDetails] = useState(null)
  const [detailsLoading, setDetailsLoading] = useState(true)
  const [detailsError, setDetailsError] = useState(null)
  const [hierarchy, setHierarchy] = useState([])
  const periodHierarchy = isRegularOrDeltaAgency() ? initalRegularAgencyPeriodHierarchy : initalBigAgencyPeriodHierarchy
  const [selectedPeriodHierarchyId, setSelectedPeriodHierarchy] = useState(null)
  const [topLevelEntityType, setTopLevelEntityType] = useState(null)
  const [openItems, setOpenItems] = useState(null)
  const [openSubItems, setOpenSubItems] = useState(null)

  const [showChanges, setShowChanges] = useState(false)
  const showChangesHandler = () => setShowChanges(!showChanges)

  const currencyType = dataType !== requestDataTypes.commissionRate
  const formatValue = (value, { maximumFractionDigits = null } = {}) =>
    currencyType
      ? formatAsCurrency(value)
      : formatAsPercent(value, { ...(maximumFractionDigits && { maximumFractionDigits }) })

  const notMonthlyChartView = periodViewType !== analyticsPeriodViewType.monthly

  let mainKey = item?.diffKey
  let typeKey = mainKey && Object.entries(incomesTotalsType).find(([k, v]) => k === mainKey)[1]
  const commissionRateAccumulationValues = typeKey === incomesTotalsType.commissionRateAccumulation

  const { t, i18n } = useTranslation('agencyHouseAnalytics')

  const getHierarchies = () => {
    return hierarchy.length > 0
      ? [
          {
            id: 'top',
            text:
              t('orderBy') +
              (hebrew()
                ? analyticsIncomesList[hierarchy[0]].name + ' -> ' + analyticsIncomesList[hierarchy[1]].name
                : analyticsIncomesList[hierarchy[0]].nameEn + ' -> ' + analyticsIncomesList[hierarchy[1]].nameEn),
            value: hierarchy[0],
            selected: hierarchy[0] === topLevelEntityType,
          },
          {
            id: 'inner',
            text:
              t('orderBy') +
              (hebrew()
                ? analyticsIncomesList[hierarchy[1]].name + ' -> ' + analyticsIncomesList[hierarchy[0]].name
                : analyticsIncomesList[hierarchy[1]].nameEn + '-> ' + analyticsIncomesList[hierarchy[0]].nameEn),
            value: hierarchy[1],
            selected: hierarchy[1] === topLevelEntityType,
          },
        ]
      : []
  }

  const getPeriodHierarchies = () =>
    periodHierarchy.map((hSet, hSetIdx) => {
      const id = hSetIdx + 1
      return {
        id,
        text:
          t('orderBy') +
          hSet.map(h => (hebrew() ? analyticsIncomesList[h].name : analyticsIncomesList[h].nameEn)).join(' -> '),
        value: id,
        selected: id === selectedPeriodHierarchyId,
      }
    })

  const currDate = new Date(item?.key)
  const prevDate = new Date(item?.key)
  prevDate.setMonth(currDate.getMonth() - 1)
  const lang = i18n.language

  const [yearCurr, monthCurr] = [currDate.getFullYear(), currDate.getMonth() + 1]
  const [yearPrev, monthPrev] = [prevDate.getFullYear(), prevDate.getMonth() + 1]

  const dateText = () => {
    if (yearCurr === yearPrev) {
      return `${showChanges ? getMonthNameByNumber(monthPrev, lang, 'short') + ' - ' : ''}${getMonthNameByNumber(
        monthCurr,
        lang,
        'short'
      )} ${yearCurr}`
    } else {
      return `${
        showChanges ? getMonthNameByNumber(monthPrev, lang, 'short') + ' ' + yearPrev + ' - ' : ''
      } ${getMonthNameByNumber(monthCurr, lang, 'short')} ${yearCurr}`
    }
  }

  const getlogo = item => {
    switch (item.entityType) {
      case analyticsIncomesListType.agents:
        return (
          <div
            className='agency-house-analytics-incomes-changes-details-item-logo'
            style={{ background: getRandomHexColor() }}
          >
            {getUserNameInitials(item.name)}
          </div>
        )
      case analyticsIncomesListType.companies:
        return (
          <div className='agency-house-analytics-incomes-changes-details-item-logo'>
            <img src={`./assets/companies-logos-light/${item.key}.png`} alt={item.name} />
          </div>
        )
      case analyticsIncomesListType.categories:
        return (
          <div
            className='agency-house-analytics-incomes-changes-details-item-logo small'
            style={{ background: categoriesObjects[item.key]?.markerColor ?? categoriesObjects['default'].markerColor }}
          ></div>
        )
      default:
        break
    }
  }

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setDetails(null)
        setDetailsLoading(true)
        setDetailsError(null)
        const getPeriodDetailsRequest = isRegularOrDeltaAgency()
          ? getAgencyAnalyticsPeriodDetailsRequest
          : getAgencyHouseAnalyticsPeriodDetailsRequest
        const { data: resp } = await getPeriodDetailsRequest({
          companies: getCheckedIds(companies, 'selected'),
          categories: getCheckedIds(categories, 'selected'),
          agenciesIds: selectedAgencies.filter(a => a.selected).map(a => a.id),
          period: item.key,
          dataType,
          entityTypeOrder: [
            analyticsIncomesExtraListType.timePeriods,
            ...periodHierarchy[selectedPeriodHierarchyId - 1],
          ],
          fromNewPolicies,
          isChartView:
            listViewType === analyticsIncomesListViewType.chart ||
            (periodViewType !== analyticsPeriodViewType.monthly && dataType !== requestDataTypes.accumulation),
          ...(isRegularOrDeltaAgency() && { calculateByFamilyGroups }),
        })

        const response = lowercaseObjectKeys(resp.Data ?? resp.data)
        setDetails(response)
        const openItems = {}
        const openSubItems = {}
        response.subItems.forEach(si => {
          openItems[si.key] = false
          si.subItems.forEach(ssi => (openSubItems[ssi.key + '' + ssi.entityType + '' + ssi.key] = false))
        })
        setOpenItems(openItems)
        setOpenSubItems(openSubItems)
        setDetailsLoading(false)
      } catch (error) {
        console.log(error)
        const cancelledReqError = error?.message?.includes('canceled')
        if (!cancelledReqError) {
          setDetailsError('Server Error')
          setDetailsLoading(false)
        }
      }
    }
    item &&
      ((topLevelEntityType && !notMonthlyChartView) || (selectedPeriodHierarchyId && notMonthlyChartView)) &&
      fetchDetails()
    return () => {
      setDetails(null)
      setDetailsLoading(true)
      setDetailsError(null)
    }
  }, [calculateByFamilyGroups, selectedAgencies, dataType, item, topLevelEntityType, selectedPeriodHierarchyId])

  useEffect(() => {
    const parentEntityType = item?.type
    let hierarchy = []
    if (isRegularOrDeltaAgency()) {
      switch (parentEntityType) {
        case analyticsIncomesListType.companies:
          hierarchy = [analyticsIncomesListType.categories]
          break
        case analyticsIncomesListType.categories:
          hierarchy = [analyticsIncomesListType.companies]
          break
        default:
          break
      }
    } else {
      switch (parentEntityType) {
        case analyticsIncomesListType.agents:
          hierarchy = [analyticsIncomesListType.companies, analyticsIncomesListType.categories]
          break
        case analyticsIncomesListType.companies:
          hierarchy = [analyticsIncomesListType.agents, analyticsIncomesListType.categories]
          break
        case analyticsIncomesListType.categories:
          hierarchy = [analyticsIncomesListType.agents, analyticsIncomesListType.companies]
          break
        default:
          break
      }
    }
    setHierarchy(hierarchy)
    setTopLevelEntityType(hierarchy[0])
    setSelectedPeriodHierarchy(1)
  }, [item])

  if (!item) {
    return (
      <div className='agency-house-analytics-incomes-changes-details-card'>
        <div className='agency-house-analytics-incomes-changes-details-card-empty'>
          <ViewListRounded />
          <p>{t('deatilsCard.emptyPlaceholder')}</p>
        </div>
      </div>
    )
  }

  if (detailsLoading) {
    return (
      <div className={cn('agency-house-analytics-incomes-changes-details-card', { 'with-changes': showChanges })}>
        <div className='agency-house-analytics-incomes-changes-details-card-empty'>
          <Loader />
        </div>
      </div>
    )
  }

  if (detailsError) {
    return (
      <div className='agency-house-analytics-incomes-changes-details-card'>
        <div className='agency-house-analytics-incomes-changes-details-card-empty'>
          <NoData text={detailsError} />
        </div>
      </div>
    )
  }

  return (
    <div className='agency-house-analytics-incomes-changes-details-card'>
      <header className='agency-house-analytics-incomes-changes-details-card-header'>
        <div>
          <p>{hebrew() ? incomesTotalsObject[typeKey].name : incomesTotalsObject[typeKey].nameEn}</p>
          <div>
            <AnalyticsChangesSwitch showChanges={showChanges} setShowChanges={showChangesHandler} />
            <p>{t('deatilsCard.comparePrevMonth')}</p>
          </div>
        </div>
        <div>
          <p>{dateText()}</p>
          {notMonthlyChartView ? (
            <div className='agency-house-analytics-incomes-changes-details-card-hierarchy'>
              <AgencyHouseAnalyticsHierarchyPicker
                hierarchies={getPeriodHierarchies()}
                setHierarchy={setSelectedPeriodHierarchy}
              />
            </div>
          ) : (
            !isRegularOrDeltaAgency() && (
              <div className='agency-house-analytics-incomes-changes-details-card-hierarchy'>
                <AgencyHouseAnalyticsHierarchyPicker
                  hierarchies={getHierarchies()}
                  setHierarchy={setTopLevelEntityType}
                />
              </div>
            )
          )}
        </div>
      </header>

      <div
        className={cn('agency-house-analytics-incomes-changes-details-card-list-container', {
          'with-changes': showChanges,
        })}
      >
        <div className='agency-house-analytics-incomes-changes-details-card-list-header agency-house-analytics-incomes-changes-gird'>
          <div></div>
          {showChanges && (
            <div>
              {hebrew() ? incomesTotalsObject[typeKey].name : incomesTotalsObject[typeKey].nameEn} (
              {getMonthNameByNumber(monthPrev, lang, 'short')})
            </div>
          )}
          <div>
            {hebrew() ? incomesTotalsObject[typeKey].name : incomesTotalsObject[typeKey].nameEn}
            {showChanges ? ' (' + getMonthNameByNumber(monthCurr, lang, 'short') + ')' : ''}
          </div>
          <div>{t('deatilsCard.changePrevMonth')}</div>
          <div></div>
          <div></div>
        </div>
        <div className='agency-house-analytics-incomes-changes-details-card-list'>
          {details.subItems.map(itm => (
            <Fragment key={itm.key}>
              <div
                className={cn(
                  'agency-house-analytics-incomes-changes-details-card-list-item agency-house-analytics-incomes-changes-gird',
                  {
                    open: openItems[itm.key],
                  }
                )}
                key={itm.key}
              >
                <div>
                  <div>{getlogo(itm)}</div>
                  <p>{hebrew() ? itm.name : itm.nameEn}</p>
                </div>
                {showChanges && (
                  <div>
                    <p>
                      {formatValue(itm[`${mainKey}Prev`], {
                        ...(commissionRateAccumulationValues && { maximumFractionDigits: 4 }),
                      })}
                    </p>
                    {dataType === requestDataTypes.commission && (
                      <span className='commission-vat'>
                        ({t('tableHeading.inclVat')}:{' '}
                        {formatValue(itm[`${getKeyByValue(incomesTotalsType, incomesTotalsType.commissionVAT)}Prev`])})
                      </span>
                    )}
                  </div>
                )}
                <div>
                  <p>
                    {formatValue(itm[mainKey], {
                      ...(commissionRateAccumulationValues && { maximumFractionDigits: 4 }),
                    })}
                  </p>
                  {dataType === requestDataTypes.commission && (
                    <span className='commission-vat'>
                      ({t('tableHeading.inclVat')}:{' '}
                      {formatValue(itm[`${getKeyByValue(incomesTotalsType, incomesTotalsType.commissionVAT)}`])})
                    </span>
                  )}
                </div>
                <AnalyticsChangesValue
                  value={itm[`${mainKey}Diff`]}
                  currency={currencyType}
                  percent={!currencyType}
                  {...(commissionRateAccumulationValues && { maximumFractionDigits: 4 })}
                />
                <div></div>
                {itm.subItems.length > 0 ? (
                  <button
                    onClick={() => {
                      setOpenItems(prev => ({ ...prev, [itm.key]: !prev[itm?.key] }))
                    }}
                  >
                    {openItems[itm.key] ? <ExpandLessTwoTone /> : <ExpandMoreTwoTone />}
                  </button>
                ) : (
                  <div></div>
                )}
              </div>
              {openItems[itm.key] && (
                <div className='agency-house-analytics-incomes-changes-details-card-sublist'>
                  {itm.subItems.map(si => (
                    <Fragment key={si.key}>
                      <div className='agency-house-analytics-incomes-changes-details-card-list-item agency-house-analytics-incomes-changes-gird'>
                        <div>
                          <div>{getlogo(si)}</div>
                          <p>{hebrew() ? si.name : si.nameEn}</p>
                        </div>
                        {showChanges && (
                          <div>
                            <p>
                              {formatValue(si[`${mainKey}Prev`], {
                                ...(commissionRateAccumulationValues && { maximumFractionDigits: 4 }),
                              })}
                            </p>
                            {dataType === requestDataTypes.commission && (
                              <span className='commission-vat'>
                                ({t('tableHeading.inclVat')}:{' '}
                                {formatValue(
                                  si[`${getKeyByValue(incomesTotalsType, incomesTotalsType.commissionVAT)}Prev`]
                                )}
                                )
                              </span>
                            )}
                          </div>
                        )}
                        <div>
                          <p>
                            {formatValue(si[mainKey], {
                              ...(commissionRateAccumulationValues && { maximumFractionDigits: 4 }),
                            })}
                          </p>
                          {dataType === requestDataTypes.commission && (
                            <span className='commission-vat'>
                              ({t('tableHeading.inclVat')}:{' '}
                              {formatValue(si[`${getKeyByValue(incomesTotalsType, incomesTotalsType.commissionVAT)}`])})
                            </span>
                          )}
                        </div>
                        <AnalyticsChangesValue
                          value={si[`${mainKey}Diff`]}
                          currency={currencyType}
                          percent={!currencyType}
                          {...(commissionRateAccumulationValues && { maximumFractionDigits: 4 })}
                        />
                        {si.subItems.length > 0 ? (
                          <button
                            onClick={() => {
                              setOpenSubItems(prev => ({
                                ...prev,
                                [itm?.key + '' + itm?.entityType + '' + si?.key]:
                                  !prev[itm?.key + '' + itm?.entityType + si?.key],
                              }))
                            }}
                          >
                            {openSubItems[itm?.key + '' + itm?.entityType + '' + si?.key] ? (
                              <ExpandLessTwoTone />
                            ) : (
                              <ExpandMoreTwoTone />
                            )}
                          </button>
                        ) : (
                          <div></div>
                        )}
                        <div></div>
                      </div>
                      {openSubItems[itm?.key + '' + itm?.entityType + '' + si?.key] && (
                        <div className='agency-house-analytics-incomes-changes-details-card-sublist'>
                          {si.subItems.map(ssi => (
                            <div
                              className='agency-house-analytics-incomes-changes-details-card-list-item inner agency-house-analytics-incomes-changes-gird'
                              key={ssi.key}
                            >
                              <div>
                                <div>{getlogo(ssi)}</div>
                                <p>{hebrew() ? ssi.name : ssi.nameEn}</p>
                              </div>
                              {showChanges && (
                                <div>
                                  <p>
                                    {formatValue(ssi[`${mainKey}Prev`], {
                                      ...(commissionRateAccumulationValues && { maximumFractionDigits: 4 }),
                                    })}
                                  </p>
                                  {dataType === requestDataTypes.commission && (
                                    <span className='commission-vat'>
                                      ({t('tableHeading.inclVat')}:{' '}
                                      {formatValue(
                                        ssi[`${getKeyByValue(incomesTotalsType, incomesTotalsType.commissionVAT)}Prev`]
                                      )}
                                      )
                                    </span>
                                  )}
                                </div>
                              )}
                              <div>
                                <p>
                                  {formatValue(ssi[mainKey], {
                                    ...(commissionRateAccumulationValues && { maximumFractionDigits: 4 }),
                                  })}
                                </p>
                                {dataType === requestDataTypes.commission && (
                                  <span className='commission-vat'>
                                    ({t('tableHeading.inclVat')}:{' '}
                                    {formatValue(
                                      ssi[`${getKeyByValue(incomesTotalsType, incomesTotalsType.commissionVAT)}`]
                                    )}
                                    )
                                  </span>
                                )}
                              </div>
                              <AnalyticsChangesValue
                                value={ssi[`${mainKey}Diff`]}
                                currency={currencyType}
                                percent={!currencyType}
                                {...(commissionRateAccumulationValues && { maximumFractionDigits: 4 })}
                              />
                              <div></div>
                              <div></div>
                            </div>
                          ))}
                        </div>
                      )}
                    </Fragment>
                  ))}
                </div>
              )}
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  )
}

export default AgencyHouseAnalyticsIncomesChangesDetailsCard
