import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { formatAsCurrency, formatAsPercent } from '../../../../utils/formatAs'
import PolicyStatusChip from '../../../../components/Common/PolicyStatusChip/PolicyStatusChip'
import { policyStatusType } from '../../../../types/policyStatusType'
import { irregularStatus } from '../../../../types/irregularStatusTypes'
import { getLastMonth } from '../../../../utils/date'
import './CustomersListItem.scss'
import { NavLink } from 'react-router-dom'
import MeatBallsMenu from '../../../../components/Common/MeatBallsMenu/MeatBallsMenu'
import { useEffect, useState } from 'react'
import AddUserModal from '../../../customer/components/AddUserModal/AddUserModal'
import { useDispatch, useSelector } from 'react-redux'
import { getAgents } from '../../../shared/actions/sharedActions'
import { sendForTreat, sendForTreatBatch } from '../../../policy/actions/policyActions'
import PrivateValue from '../../../../HOCs/PrivateValue'
import { userPreferenceType } from '../../../../types/agencyUserTypes'
import CustomerActionModal from '../../../customer/components/CustomerActionModal/CustomerActionModal'
import { CUSTOMER_UNCHECK_ALL, POLICY_TREAT_RESET } from '../../../../types/actionTypes'
import CustomerAllCategoriesModal from '../../../customer/components/CustomerAllCategoriesModal/CustomerAllCategoriesModal'
import AddCustomerGroupModal from '../../../../components/AddCustomerGroupModal/AddCustomerGroupModal'
import { getCustomersTotalDashboard } from '../../actions/customersActions'
import { hebrew } from '../../../../i18n'
import { cn } from '../../../../utils/stylesUtils'
import { commissionKeySuffix, deltaCommissionSplitView } from '../../../shared/reducers/sharedSelectors'

const CustomersListItem = ({ item, checked, checkCustomer }) => {
  const [showAddMemeberModal, setShowAddMemeberModal] = useState(false)
  const [showAgentsModal, setShowAgentsModal] = useState(false)
  const [showTreatActionModal, setShowTreatActionModal] = useState(false)
  const [showCategoriesModal, setShowCategoriesModal] = useState(false)
  const [treatInfo, setTreatInfo] = useState(null)
  const dispatch = useDispatch()
  const agents = useSelector(state => state.shared).agents
  const agentsLoading = useSelector(state => state.shared).agentsLoading
  const treatSuccess = useSelector(state => state.policy).treatSuccess
  const treatLoading = useSelector(state => state.policy).treatLoading

  const addTreatAgent = agent => {
    setTreatInfo({ ...treatInfo, agentId: agent.id, agentName: agent.name })
    setShowAgentsModal(false)
    setShowTreatActionModal(true)
  }

  const selectAction = actionId => {
    setTreatInfo({ ...treatInfo, actionId })
  }

  const sendForTreatHandler = () => {
    const agentId = treatInfo.agentId
    const type = treatInfo.actionId
    const treatDtos = [
      {
        agentId,
        customerId: item.id,
        type,
      },
    ]
    dispatch(sendForTreatBatch(treatDtos))
  }

  const addTreatAgentNote = note => {
    setTreatInfo({ ...treatInfo, note })
    const treatDto = {
      agentId: treatInfo.agentId,
      customerId: item.id,
      note,
    }
    dispatch(sendForTreat(treatDto))
  }

  const { t } = useTranslation('customers')
  const history = useHistory()
  const customerStatuses = []
  new Date(item.pullingDate) >= getLastMonth().from && customerStatuses.push(policyStatusType.new)
  !item.active && customerStatuses.push(policyStatusType.inactive)
  item.irregularStatus === irregularStatus.irregular && customerStatuses.push(policyStatusType.irregular)
  customerStatuses.push(item.policyRating)

  const menuItems = [
    {
      text: t('customerMenu.addToGroup'),
      clickHandler: () => setShowAddMemeberModal(true),
    },
    {
      text: t('customerMenu.sendTreat'),
      clickHandler: () => setShowAgentsModal(true),
    },
    {
      text: t('customerMenu.examineProduct'),
      clickHandler: () => setShowCategoriesModal(true),
    },
    {
      text: t('customerMenu.addExternalCommission'),
      clickHandler: () => console.log('addExternalCommission'),
      disabled: true,
      blured: true,
    },
    {
      text: t('customerMenu.share'),
      clickHandler: () => console.log('share'),
      disabled: true,
      blured: true,
    },
  ]

  const checkHandler = e => {
    e.stopPropagation()
    checkCustomer()
  }

  useEffect(() => {
    if (showAgentsModal) {
      dispatch(getAgents())
    }
  }, [showAgentsModal, dispatch])

  return (
    <>
      <div className={cn('customers-list-item-wrapper', { inactive: !item.active, checked })}>
        <div
          className={cn('customers-list-item', {
            inactive: !item.active,
            checked,
            'split-commission': deltaCommissionSplitView(),
          })}
          onClick={() => history.push(`/customers/${item.id}`)}
        >
          <div className='customers-list-item-checkbox'>
            <img
              onClick={e => checkHandler(e, item.id)}
              src={`./assets/checkbox-sq/${checked ? 'A' : 'Ina'}ctive.png`}
              alt={checked ? 'unchk' : 'chk'}
            />
          </div>
          <div>
            <NavLink exact to={`/customers/${item.id}`}>
              {item.customerName}
            </NavLink>
          </div>
          <div className='customer-id-number'>{item.customerIdNumber}</div>
          <PrivateValue requiredPrefernces={[userPreferenceType.premium]}>
            <div
              style={{
                direction: 'ltr',
                ...(hebrew() && { textAlign: 'end' }),
              }}
            >
              {formatAsCurrency(item.premium)}
            </div>
          </PrivateValue>
          <PrivateValue requiredPrefernces={[userPreferenceType.comission]}>
            <div
              style={{
                direction: 'ltr',
                ...(hebrew() && { textAlign: 'end' }),
              }}
            >
              {formatAsCurrency(item[`commission${commissionKeySuffix()}`])}
            </div>
          </PrivateValue>
          {deltaCommissionSplitView() && (
            <PrivateValue requiredPrefernces={[userPreferenceType.comission]}>
              <div
                style={{
                  direction: 'ltr',
                  ...(hebrew() && { textAlign: 'end' }),
                }}
              >
                {formatAsCurrency(item.commissionAgency)}
              </div>
            </PrivateValue>
          )}
          <PrivateValue requiredPrefernces={[userPreferenceType.accumulation]}>
            <div
              style={{
                direction: 'ltr',
                ...(hebrew() && { textAlign: 'end' }),
              }}
            >
              {formatAsCurrency(item.accumulation)}
            </div>
          </PrivateValue>
          <PrivateValue requiredPrefernces={[userPreferenceType.comissionRate]}>
            <div dir={hebrew() ? 'rtl' : 'ltr'} style={{ textAlign: 'center' }} className='commission-rate'>
              <div className='commission-split'>
                <div>{formatAsPercent(item[`commissionRatePremium${commissionKeySuffix()}`])}</div>
                <div>{formatAsPercent(item[`commissionRateAccumulation${commissionKeySuffix()}`])}</div>
              </div>
            </div>
          </PrivateValue>
          {deltaCommissionSplitView() && (
            <PrivateValue requiredPrefernces={[userPreferenceType.comissionRate]}>
              <div dir={hebrew() ? 'rtl' : 'ltr'} style={{ textAlign: 'center' }} className='commission-rate'>
                <div className='commission-split'>
                  <div>{formatAsPercent(item.commissionRateAccumulationAgency)}</div>
                  <div>{formatAsPercent(item.commissionRatePremiumAgency)}</div>
                </div>
              </div>
            </PrivateValue>
          )}
          <div className='customer-status'>
            {customerStatuses.map(ps => (
              <PolicyStatusChip status={ps} key={ps} shortName={customerStatuses.length > 1} />
            ))}
          </div>
          <div className='customer-menu'>
            <MeatBallsMenu menuItems={menuItems} positionFixed />
          </div>
        </div>
      </div>
      {showAddMemeberModal && (
        <AddCustomerGroupModal
          closeModal={() => setShowAddMemeberModal(false)}
          groupCustomers={[
            {
              ...item,
              name: item.customerName,
            },
          ]}
          onAddSuccess={() => dispatch(getCustomersTotalDashboard({ silentLoading: true }))}
        />
      )}
      {showAgentsModal && (
        <AddUserModal
          users={agents}
          loading={agentsLoading}
          closeModal={() => {
            setShowAgentsModal(false)
          }}
          closeOnOutsideClick={false}
          onAddUser={addTreatAgent}
          currentUser={item?.treatmentInfo?.agentId}
          askConfirm={item?.treatmentInfo?.agentId && item?.treatmentInfo?.agentId !== treatInfo?.currAgent}
        />
      )}
      {showTreatActionModal && (
        <CustomerActionModal
          closeModal={() => setShowTreatActionModal(false)}
          closeOnOutsideClick={false}
          onSelectAction={selectAction}
          onSubmit={sendForTreatHandler}
          submitSuccess={treatSuccess}
          resetSuccess={() => {
            dispatch({ type: POLICY_TREAT_RESET })
            dispatch({ type: CUSTOMER_UNCHECK_ALL })
          }}
          loading={treatLoading}
          currentAction={treatInfo?.actionId}
          back={() => setShowAgentsModal(true)}
          agentName={treatInfo?.name}
        />
      )}
      {showCategoriesModal && (
        <CustomerAllCategoriesModal closeModal={() => setShowCategoriesModal(false)} customerId={item.id} />
      )}
    </>
  )
}

export default CustomersListItem
