import { useTranslation } from 'react-i18next'
import './AgencyHouseAnalyticsIncomesDetailsCard.scss'
import { useEffect, useState, useCallback } from 'react'
import { CloseRounded, ExpandLessTwoTone, ExpandMoreTwoTone, ViewListRounded } from '@mui/icons-material'
import {
  getAgencyHouseAnalyticsDetailsRequest,
  getAgencyHouseAnalyticsPeriodDetailsRequest,
} from '../../../../http/requests/agencyHouseRequests'
import { useSelector } from 'react-redux'
import { getCheckedIds, lowercaseObjectKeys } from '../../../../utils/objectUtils'
import {
  analyticsIncomesExtraListType,
  analyticsIncomesList,
  analyticsIncomesListType,
  analyticsIncomesListViewType,
  getIncomesTotalsTypes,
  incomesTotalsObject,
  incomesTotalsType,
} from '../../../../types/analyticsIncomesTypes'
import { hebrew } from '../../../../i18n'
import { getMonthNameByNumber } from '../../../../services/getMonthName'
import Loader from '../../../../components/Common/Loader/Loader'
import classNames from 'classnames'
import { getRandomHexColor } from '../../../../services/getRandom'
import { getUserNameInitials } from '../../../../services/getUserNameInitials'
import { categoriesObjects } from '../../../../types/categoriesTypes'
import AgencyHouseAnalyticsHierarchyPicker from '../AgencyHouseAnalyticsHierarchyPicker/AgencyHouseAnalyticsHierarchyPicker'
import { requestDataTypes } from '../../../../types/requestDataTypes'
import { formatAsCurrency, formatAsPercent } from '../../../../utils/formatAs'
import AnnualDistributionChart from '../../../../components/Charts/AnnualDistributionChart'
import { Fragment } from 'react-is'
import { isRegularOrDeltaAgency } from '../../../login/reducers/loginSelectors'
import {
  getAgencyAnalyticsDetailsRequest,
  getAgencyAnalyticsPeriodDetailsRequest,
} from '../../../../http/requests/agencyAnalyticsRequests'
import { analyticsPeriodViewType } from '../../../../types/analyticsTypes'
import NoData from '../../../../components/Common/NoData/NoData'
import { familyGroupMode } from '../../../shared/reducers/sharedSelectors'

const initalBigAgencyPeriodHierarchy = [
  [analyticsIncomesListType.agents, analyticsIncomesListType.companies, analyticsIncomesListType.categories],
  [analyticsIncomesListType.agents, analyticsIncomesListType.categories, analyticsIncomesListType.companies],
  [analyticsIncomesListType.companies, analyticsIncomesListType.agents, analyticsIncomesListType.categories],
  [analyticsIncomesListType.companies, analyticsIncomesListType.categories, analyticsIncomesListType.agents],
  [analyticsIncomesListType.categories, analyticsIncomesListType.agents, analyticsIncomesListType.companies],
  [analyticsIncomesListType.categories, analyticsIncomesListType.companies, analyticsIncomesListType.agents],
]

const initalRegularAgencyPeriodHierarchy = [
  [analyticsIncomesListType.companies, analyticsIncomesListType.categories],
  [analyticsIncomesListType.categories, analyticsIncomesListType.companies],
]

const AgencyHouseAnalyticsIncomesDetailsCard = ({ item, closeCard, dataType, fromNewPolicies, listViewType }) => {
  const { selectedDates, selectedAgencies, companies, categories, periodViewType } = useSelector(
    ({ agencyHouseAnalytics }) => agencyHouseAnalytics
  )
  const calculateByFamilyGroups = familyGroupMode()

  const [details, setDetails] = useState(null)
  const [detailsLoading, setDetailsLoading] = useState(true)
  const [detailsError, setDetailsError] = useState(null)
  const [hierarchy, setHierarchy] = useState([])
  const periodHierarchy = isRegularOrDeltaAgency() ? initalRegularAgencyPeriodHierarchy : initalBigAgencyPeriodHierarchy
  const [selectedPeriodHierarchyId, setSelectedPeriodHierarchy] = useState(null)
  const [topLevelEntityType, setTopLevelEntityType] = useState(null)
  const [openItems, setOpenItems] = useState(null)
  const [openSubItems, setOpenSubItems] = useState(null)

  const notMonthlyChartView =
    +listViewType === analyticsIncomesListViewType.chart &&
    periodViewType !== analyticsPeriodViewType.monthly &&
    dataType !== requestDataTypes.accumulation

  const totalsTypes = getIncomesTotalsTypes(dataType, periodViewType)
  let mainKey
  let mainKeyValue
  let keys = []
  let allKeys = []
  Object.entries(incomesTotalsType)
    .filter(([k, v]) => totalsTypes.includes(v))
    .forEach(([k, v]) => {
      if (incomesTotalsObject[v].detailsMainValue) {
        mainKey = k
        mainKeyValue = v
      } else {
        keys.push(k)
      }
      allKeys.push({ key: k, keyValue: v })
    })
  const { t, i18n } = useTranslation('agencyHouseAnalytics')

  const getHierarchies = () => {
    return hierarchy.length > 0
      ? [
          {
            id: 'top',
            text:
              t('orderBy') +
              (hebrew()
                ? analyticsIncomesList[hierarchy[0]].name + ' -> ' + analyticsIncomesList[hierarchy[1]].name
                : analyticsIncomesList[hierarchy[0]].nameEn + ' -> ' + analyticsIncomesList[hierarchy[1]].nameEn),
            value: hierarchy[0],
            selected: hierarchy[0] === topLevelEntityType,
          },
          {
            id: 'inner',
            text:
              t('orderBy') +
              (hebrew()
                ? analyticsIncomesList[hierarchy[1]].name + ' -> ' + analyticsIncomesList[hierarchy[0]].name
                : analyticsIncomesList[hierarchy[1]].nameEn + '-> ' + analyticsIncomesList[hierarchy[0]].nameEn),
            value: hierarchy[1],
            selected: hierarchy[1] === topLevelEntityType,
          },
        ]
      : []
  }

  const getPeriodHierarchies = () =>
    periodHierarchy.map((hSet, hSetIdx) => {
      const id = hSetIdx + 1
      return {
        id,
        text:
          t('orderBy') +
          hSet.map(h => (hebrew() ? analyticsIncomesList[h].name : analyticsIncomesList[h].nameEn)).join(' -> '),
        value: id,
        selected: id === selectedPeriodHierarchyId,
      }
    })

  const dateText = () => {
    const lang = i18n.language
    const dates = selectedDates.filter(d => d.selected)
    if (dates.length === 0) {
      return 'For All Time'
    }
    const [yearFrom, monthFrom] = dates.at(0)?.date.toString().split('-')
    const [yearTo, monthTo] = dates.at(-1)?.date.toString().split('-')
    if (yearFrom === yearTo) {
      return `${getMonthNameByNumber(monthFrom, lang, 'short')}${
        monthFrom !== monthTo ? ' - ' + getMonthNameByNumber(monthTo, lang, 'short') : ''
      } ${yearFrom}`
    } else {
      return `${getMonthNameByNumber(monthFrom, lang, 'short')} ${yearFrom} - ${getMonthNameByNumber(
        monthTo,
        lang,
        'short'
      )} ${yearTo}`
    }
  }

  const getlogo = item => {
    switch (item.entityType) {
      case analyticsIncomesListType.agents:
        return (
          <div className='agency-house-analytics-incomes-details-item-logo' style={{ background: getRandomHexColor() }}>
            {getUserNameInitials(item.name)}
          </div>
        )
      case analyticsIncomesListType.companies:
        return (
          <div className='agency-house-analytics-incomes-details-item-logo'>
            <img src={`./assets/companies-logos-light/${item.key}.png`} alt={item.name} />
          </div>
        )
      case analyticsIncomesListType.categories:
        return (
          <div
            className='agency-house-analytics-incomes-details-item-logo small'
            style={{ background: categoriesObjects[item.key]?.markerColor ?? categoriesObjects['default'].markerColor }}
          ></div>
        )
      default:
        break
    }
  }

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setDetails(null)
        setDetailsLoading(true)
        setDetailsError(null)
        let resp
        if (!notMonthlyChartView) {
          const getDetailsRequest = isRegularOrDeltaAgency()
            ? getAgencyAnalyticsDetailsRequest
            : getAgencyHouseAnalyticsDetailsRequest
          const { data } = await getDetailsRequest({
            companies: getCheckedIds(companies, 'selected'),
            categories: getCheckedIds(categories, 'selected'),
            agenciesIds: selectedAgencies.filter(a => a.selected).map(a => a.key),
            timePeriods: selectedDates.filter(a => a.selected).map(a => a.date),
            dataType,
            mainEntityType: item.type,
            mainEntityId: +item.key,
            topLevelEntityType,
            fromNewPolicies,
            isChartView: listViewType === analyticsIncomesListViewType.chart,
            ...(isRegularOrDeltaAgency() && { calculateByFamilyGroups }),
          })
          resp = data
        } else {
          const getPeriodDetailsRequest = isRegularOrDeltaAgency()
            ? getAgencyAnalyticsPeriodDetailsRequest
            : getAgencyHouseAnalyticsPeriodDetailsRequest
          const { data } = await getPeriodDetailsRequest({
            companies: getCheckedIds(companies, 'selected'),
            categories: getCheckedIds(categories, 'selected'),
            agenciesIds: selectedAgencies.filter(a => a.selected).map(a => a.key),
            period: item.key,
            dataType,
            entityTypeOrder: [
              analyticsIncomesExtraListType.timePeriods,
              ...periodHierarchy[selectedPeriodHierarchyId - 1],
            ],
            fromNewPolicies,
            isChartView: listViewType === analyticsIncomesListViewType.chart,
            ...(isRegularOrDeltaAgency() && { calculateByFamilyGroups }),
          })
          resp = data
        }
        const response = lowercaseObjectKeys(resp.Data ?? resp.data)
        setDetails(response)
        const openItems = {}
        const openSubItems = {}
        response.subItems.forEach(si => {
          openItems[si.key] = false
          si.subItems.forEach(ssi => (openSubItems[ssi.key + '' + ssi.entityType + '' + ssi.key] = false))
        })
        setOpenItems(openItems)
        setOpenSubItems(openSubItems)
        setDetailsLoading(false)
      } catch (error) {
        console.log(error)
        const cancelledReqError = error?.message?.includes('canceled')
        if (!cancelledReqError) {
          setDetailsError('Server Error')
          setDetailsLoading(false)
        }
      }
    }
    item &&
      ((topLevelEntityType && !notMonthlyChartView) || (selectedPeriodHierarchyId && notMonthlyChartView)) &&
      fetchDetails()
    return () => {
      setDetails(null)
      setDetailsLoading(true)
      setDetailsError(null)
    }
  }, [calculateByFamilyGroups, selectedAgencies, dataType, item, topLevelEntityType, selectedPeriodHierarchyId])

  useEffect(() => {
    const parentEntityType = item?.type
    let hierarchy = []
    if (isRegularOrDeltaAgency()) {
      switch (parentEntityType) {
        case analyticsIncomesListType.companies:
          hierarchy = [analyticsIncomesListType.categories]
          break
        case analyticsIncomesListType.categories:
          hierarchy = [analyticsIncomesListType.companies]
          break
        default:
          break
      }
    } else {
      switch (parentEntityType) {
        case analyticsIncomesListType.agents:
          hierarchy = [analyticsIncomesListType.companies, analyticsIncomesListType.categories]
          break
        case analyticsIncomesListType.companies:
          hierarchy = [analyticsIncomesListType.agents, analyticsIncomesListType.categories]
          break
        case analyticsIncomesListType.categories:
          hierarchy = [analyticsIncomesListType.agents, analyticsIncomesListType.companies]
          break
        default:
          break
      }
    }
    setHierarchy(hierarchy)
    setTopLevelEntityType(hierarchy[0])
    setSelectedPeriodHierarchy(1)
  }, [item])

  if (!item) {
    return (
      <div className='agency-house-analytics-incomes-details-card'>
        <div className='agency-house-analytics-incomes-details-card-empty'>
          <ViewListRounded />
          <p>{t('deatilsCard.emptyPlaceholder')}</p>
        </div>
      </div>
    )
  }

  if (detailsLoading) {
    return (
      <div className='agency-house-analytics-incomes-details-card'>
        <div className='agency-house-analytics-incomes-details-card-empty'>
          <Loader />
        </div>
      </div>
    )
  }

  if (detailsError) {
    return (
      <div className='agency-house-analytics-incomes-details-card'>
        <div className='agency-house-analytics-incomes-details-card-empty'>
          <NoData text={detailsError} />
        </div>
      </div>
    )
  }

  return (
    <div className='agency-house-analytics-incomes-details-card'>
      <header className='agency-house-analytics-incomes-details-card-header'>
        <div>
          <p>
            {hebrew()
              ? incomesTotalsObject[mainKeyValue].detailsHeaderName
              : incomesTotalsObject[mainKeyValue].detailsHeaderNameEn}
            {dataType === requestDataTypes.commissionRate
              ? formatAsPercent(details[mainKey])
              : formatAsCurrency(details[mainKey])}
          </p>
          <button onClick={closeCard}>
            <CloseRounded />
          </button>
        </div>
        <span>{dateText()}</span>
      </header>
      {notMonthlyChartView ? (
        <div className='agency-house-analytics-incomes-details-card-hierarchy'>
          <AgencyHouseAnalyticsHierarchyPicker
            hierarchies={getPeriodHierarchies()}
            setHierarchy={setSelectedPeriodHierarchy}
          />
        </div>
      ) : (
        !isRegularOrDeltaAgency() && (
          <div className='agency-house-analytics-incomes-details-card-hierarchy'>
            <AgencyHouseAnalyticsHierarchyPicker hierarchies={getHierarchies()} setHierarchy={setTopLevelEntityType} />
          </div>
        )
      )}
      <div className='agency-house-analytics-incomes-details-card-chart'>
        <AnnualDistributionChart
          series={details.subItems.filter(si => si[mainKey]).map(si => si[mainKey])}
          labels={details.subItems.filter(si => si[mainKey]).map(si => (hebrew() ? si.name : si.nameEn))}
          colors={details.subItems
            .filter(si => si[mainKey])
            .map(({ key }) => categoriesObjects[key]?.markerColor ?? categoriesObjects.default.markerColor)}
          valueFormatter={val =>
            dataType === requestDataTypes.commissionRate ? formatAsPercent(val) : formatAsCurrency(val)
          }
        />
      </div>
      <div className='agency-house-analytics-incomes-details-card-list-container'>
        <h5>
          {!notMonthlyChartView
            ? hebrew()
              ? analyticsIncomesList[topLevelEntityType]?.detailsName
              : analyticsIncomesList[topLevelEntityType]?.detailsNameEn
            : hebrew()
            ? analyticsIncomesList[periodHierarchy[selectedPeriodHierarchyId - 1][0]]?.detailsName
            : analyticsIncomesList[periodHierarchy[selectedPeriodHierarchyId - 1][0]]?.detailsNameEn}
          {}
        </h5>
        <div className='agency-house-analytics-incomes-details-card-list'>
          {details.subItems.map(itm => (
            <Fragment key={itm.key}>
              <div
                className={classNames('agency-house-analytics-incomes-details-card-list-item', {
                  open: openItems[itm.key],
                })}
                key={itm.key}
              >
                <div>
                  {getlogo(itm)}
                  <p>{hebrew() ? itm.name : itm.nameEn}</p>
                </div>
                <div>
                  {dataType === requestDataTypes.accumulation && periodViewType !== analyticsPeriodViewType.monthly ? (
                    <div className='details-card-grid'>
                      {allKeys.map(({ key: k, keyValue: v }) => (
                        <div key={k}>
                          <div className='details-card-grid-header'>
                            {hebrew() ? incomesTotalsObject[v].shortName : incomesTotalsObject[v].shortNameEn}
                          </div>
                          <div>
                            {itm[k] !== null
                              ? new Intl.NumberFormat('he-IL', {
                                  style: 'currency',
                                  notation: 'compact',
                                  currency: 'ILS',
                                }).format(itm[k])
                              : '--'}
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p>
                      {dataType === requestDataTypes.commissionRate
                        ? formatAsPercent(itm[mainKey])
                        : formatAsCurrency(itm[mainKey])}
                    </p>
                  )}
                  {itm.subItems.length > 0 && (
                    <button
                      onClick={() => {
                        setOpenItems(prev => ({ ...prev, [itm.key]: !prev[itm?.key] }))
                      }}
                    >
                      {openItems[itm.key] ? <ExpandLessTwoTone /> : <ExpandMoreTwoTone />}
                    </button>
                  )}
                </div>
              </div>
              {openItems[itm.key] && (
                <div className='agency-house-analytics-incomes-details-card-sublist'>
                  {itm.subItems.map(si => (
                    <Fragment key={si.key}>
                      <div className='agency-house-analytics-incomes-details-card-sublist-item'>
                        <div>
                          {getlogo(si)}
                          <p>{hebrew() ? si.name : si.nameEn}</p>
                        </div>
                        <div>
                          {dataType === requestDataTypes.accumulation &&
                          periodViewType !== analyticsPeriodViewType.monthly ? (
                            <div className='details-card-grid'>
                              {allKeys.map(({ key: k }) => (
                                <div key={k}>
                                  <div>
                                    {si[k] !== null
                                      ? new Intl.NumberFormat('he-IL', {
                                          style: 'currency',
                                          notation: 'compact',
                                          currency: 'ILS',
                                        }).format(si[k])
                                      : '--'}
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <p>
                              {dataType === requestDataTypes.commissionRate
                                ? formatAsPercent(si[mainKey])
                                : formatAsCurrency(si[mainKey])}
                            </p>
                          )}
                          {si.subItems.length > 0 && (
                            <button
                              onClick={() => {
                                setOpenSubItems(prev => ({
                                  ...prev,
                                  [itm?.key + '' + itm?.entityType + '' + si?.key]:
                                    !prev[itm?.key + '' + itm?.entityType + si?.key],
                                }))
                              }}
                            >
                              {openSubItems[itm?.key + '' + itm?.entityType + '' + si?.key] ? (
                                <ExpandLessTwoTone />
                              ) : (
                                <ExpandMoreTwoTone />
                              )}
                            </button>
                          )}
                        </div>
                      </div>
                      {openSubItems[itm?.key + '' + itm?.entityType + '' + si?.key] && (
                        <div className='agency-house-analytics-incomes-details-card-sublist'>
                          {si.subItems.map(ssi => (
                            <div
                              className='agency-house-analytics-incomes-details-card-sublist-item inner'
                              key={ssi.key}
                            >
                              <div>
                                {getlogo(ssi)}
                                <p>{hebrew() ? ssi.name : ssi.nameEn}</p>
                              </div>
                              <div>
                                {dataType === requestDataTypes.accumulation &&
                                periodViewType !== analyticsPeriodViewType.monthly ? (
                                  <div className='details-card-grid'>
                                    {allKeys.map(({ key: k }) => (
                                      <div key={k}>
                                        <div>
                                          {ssi[k] !== null
                                            ? new Intl.NumberFormat('he-IL', {
                                                style: 'currency',
                                                notation: 'compact',
                                                currency: 'ILS',
                                              }).format(ssi[k])
                                            : '--'}
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                ) : (
                                  <p>
                                    {dataType === requestDataTypes.commissionRate
                                      ? formatAsPercent(ssi[mainKey])
                                      : formatAsCurrency(ssi[mainKey])}
                                  </p>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </Fragment>
                  ))}
                </div>
              )}
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  )
}

export default AgencyHouseAnalyticsIncomesDetailsCard
