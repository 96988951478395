import store from '../../../app/store'
import { agencyType } from '../../../types/agencyTypes'
import { deltaCommissionViewType } from '../../../types/deltaCommissionViewTypes'
import { familyGroupType } from '../../../types/familyGroupTypes'

export const familyGroupMode = () => store.getState().shared.familyGroupsType === familyGroupType.group

export const isDeltaAgency = () => store.getState().login.AgencyType === agencyType.delta;

export const deltaCommissionSplitView = () =>
  store.getState().login.AgencyType === agencyType.delta &&
  store.getState().shared.commissionView === deltaCommissionViewType.bothSideBySide

export const commissionKeySuffix = () => {
  if (store.getState().login.AgencyType === agencyType.delta) {
    if (store.getState().shared.commissionView === deltaCommissionViewType.agency) {
      return 'Agency'
    }
    if (store.getState().shared.commissionView === deltaCommissionViewType.bothTogether) {
      return 'BothTotal'
    }
  }
  return ''
}

export const commissionHeaderKeySuffix = () => {
  if (store.getState().login.AgencyType === agencyType.delta) {
    if (store.getState().shared.commissionView === deltaCommissionViewType.agency) {
      return 'Agency'
    }
    if (store.getState().shared.commissionView === deltaCommissionViewType.agent) {
      return 'Agents'
    }
    if (store.getState().shared.commissionView === deltaCommissionViewType.bothSideBySide) {
      return 'Agents'
    }
  }
  return ''
}
