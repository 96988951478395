import { useTranslation } from 'react-i18next'
import NoData from '../../../../components/Common/NoData/NoData'
import { hebrew } from '../../../../i18n'
import { formatAsCurrency, formatAsPercent } from '../../../../utils/formatAs'
import { useEffect, useRef, useState } from 'react'
import PolicyDetails from '../../../policy/components/PolicyDetails/PolicyDetails'
import { SyncProblemOutlined } from '@mui/icons-material'
import { cn } from '../../../../utils/stylesUtils'
import PolicyStatusChip from '../../../../components/Common/PolicyStatusChip/PolicyStatusChip'
import { policyStatusType } from '../../../../types/policyStatusType'
import { irregularStatus } from '../../../../types/irregularStatusTypes'
import { insuranceField } from '../../../../types/insuranceField'
import {
  commissionHeaderKeySuffix,
  commissionKeySuffix,
  deltaCommissionSplitView,
  familyGroupMode,
} from '../../../shared/reducers/sharedSelectors'
import { Tooltip } from '@mui/material'
import './CustomerPoliciesList.scss'

const CustomerPoliciesList = ({ policies = [], policiesTotals }) => {
  const [selectedPolicy, setSelectedPolicy] = useState(null)
  const { t } = useTranslation('customer')
  const listRef = useRef(null)

  const selectPolicyHandler = policy => {
    const field = Object.values(insuranceField).find(f => f.insuranceCategories.includes(policy.categoryId))
    setSelectedPolicy({
      ...policy,
      active: !policy.isNotActive,
      fieldId: field?.id,
      fieldName: field?.name,
      fieldNameEn: field?.nameEn,
      irregularStatus: policy.isIrregular ? irregularStatus.irregular : irregularStatus.normal,
    })
  }

  useEffect(() => {
    listRef.current.scrollIntoView()
  }, [])

  if (!policies || policies.length === 0) {
    return (
      <div className='customer-policies-list' ref={listRef}>
        <NoData text='No Data' />
      </div>
    )
  }

  return (
    <>
      <section className='customer-policies-list-container'>
        <header
          className={cn('customer-policies-list-grid', {
            extended: familyGroupMode(),
            'split-delta-commission': deltaCommissionSplitView(),
          })}
        >
          <p>{t('policiesListHeadings.policyNumber')}</p>
          {familyGroupMode() && (
            <>
              <p>{t('policiesListHeadings.customerIdNumber')}</p>
              <p>{t('policiesListHeadings.customerName')}</p>
            </>
          )}
          <p>{t('policiesListHeadings.category')}</p>
          <p>{t('policiesListHeadings.company')}</p>
          <p>{t('policiesListHeadings.premium')}</p>
          {deltaCommissionSplitView() && <p>{t('policiesListHeadings.commissionAgency')}</p>}
          <p>{t(`policiesListHeadings.commission${commissionHeaderKeySuffix()}`)}</p>
          <p>{t('policiesListHeadings.accumulation')}</p>
          {deltaCommissionSplitView() && (
            <div>
              <p>{t('policiesListHeadings.cRateAgency')}</p>
              <div className='commission-rate-split'>
                <p>{t('policiesListHeadings.premium')}</p>
                <p>{t('policiesListHeadings.accumulation')}</p>
              </div>
            </div>
          )}
          <div>
            <p>{t(`policiesListHeadings.cRate${commissionHeaderKeySuffix()}`)}</p>
            <div className='commission-rate-split'>
              <p>{t('policiesListHeadings.premium')}</p>
              <p>{t('policiesListHeadings.accumulation')}</p>
            </div>
          </div>
          <div></div>
          <div></div>
        </header>
        <div className='customer-policies-list' ref={listRef}>
          {policies.map(p => (
            <div
              className={cn('customer-policies-list-item customer-policies-list-grid', {
                'out-dated': p.outOfDate,
                inactive: p.isNotActive,
                extended: familyGroupMode(),
                'split-delta-commission': deltaCommissionSplitView(),
              })}
              key={p.id}
              onClick={() => selectPolicyHandler(p)}
            >
              <p>{p.policyNumber}</p>
              {familyGroupMode() && (
                <>
                  <p>{p.customerIdNumber}</p>
                  <p>{p.customerName}</p>
                </>
              )}
              <p>{hebrew() ? p.categoryName : p.categoryNameEn}</p>
              <p>{hebrew() ? p.companyName : p.companyNameEn}</p>
              <p>{formatAsCurrency(p.premium)}</p>
              {deltaCommissionSplitView() && <p>{formatAsCurrency(p.commissionAgency)}</p>}
              <p>{formatAsCurrency(p[`commission${commissionKeySuffix()}`])}</p>
              <p>{formatAsCurrency(p.accumulation)}</p>
              {deltaCommissionSplitView() && (
                <div className='commission-rate-split'>
                  <p>{formatAsPercent(p.commissionRatePremiumAgency)}</p>
                  <p>{formatAsPercent(p.commissionRateAccumulationAgency)}</p>
                </div>
              )}
              <div className='commission-rate-split'>
                <p>{formatAsPercent(p[`commissionRatePremium${commissionKeySuffix()}`])}</p>
                <p>{formatAsPercent(p[`commissionRateAccumulation${commissionKeySuffix()}`])}</p>
              </div>
              <div>
                {p.outOfDate && (
                  <Tooltip
                    title={t('tooltips.outdatedPolicy')}
                    classes={{ popper: 'MuiTooltip-tooltip-custom' }}
                    arrow
                    placement='top'
                  >
                    <SyncProblemOutlined style={{ color: 'var(--orange-client)' }} />
                  </Tooltip>
                )}
              </div>
              <div>
                <div className='customer-policies-list-item-badges-wrapper'>
                  {p.isNotActive && <PolicyStatusChip status={policyStatusType.inactive} />}
                  {p.isIrregular && <PolicyStatusChip status={policyStatusType.irregular} />}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div
          className={cn('customer-policies-list-item-totals customer-policies-list-grid', {
            extended: familyGroupMode(),
            'split-delta-commission': deltaCommissionSplitView(),
          })}
        >
          <p className='totals-main-cell'>{t('policiesListHeadings.total')}</p>
          <div></div>
          <div></div>
          {familyGroupMode() && (
            <>
              <div></div>
              <div></div>
            </>
          )}
          <p>{formatAsCurrency(policiesTotals.premium)}</p>
          {deltaCommissionSplitView() && <p>{formatAsCurrency(policiesTotals.commissionAgency)}</p>}
          <p>{formatAsCurrency(policiesTotals[`commission${commissionKeySuffix()}`])}</p>
          <p>{formatAsCurrency(policiesTotals.accumulation)}</p>
          <div></div>
          {deltaCommissionSplitView() && <div></div>}
          <div></div>
          <div></div>
        </div>
      </section>
      {selectedPolicy && <PolicyDetails policy={selectedPolicy} closeDetails={() => setSelectedPolicy(null)} />}
    </>
  )
}

export default CustomerPoliciesList
