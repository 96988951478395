export const sortBy = {
  name: 'Name',
  commission: 'Commission',
  commissionAgency: 'CommissionAgency',
  commissionAgents: 'CommissionAgents',
  commissionBothTotal: 'CommissionBothTotal',
  commissionRate: 'CommissionRate',
  commissionRatePremium: 'CommissionRatePremium',
  commissionRateAccumulation: 'CommissionRateAccumulation',
  commissionRateAgency: 'CommissionRateAgency',
  commissionRatePremiumAgency: 'CommissionRatePremiumAgency',
  commissionRateAccumulationAgency: 'CommissionRateAccumulationAgency',
  commissionRateBothTotal: 'CommissionRateBothTotal',
  commissionRatePremiumBothTotal: 'CommissionRatePremiumBothTotal',
  commissionRateAccumulationBothTotal: 'CommissionRateAccumulationBothTotal',
  premium: 'Premium',
  accumulation: 'Accumulation',
  idNumber: 'idNumber',
  company: 'Company',
  policyNumber: 'PolicyNumber',
  category: 'Category',
  customerName: 'CustomerName',
  customerIdNumber: 'CustomerIdNumber',
  taskType: 'TaskType',
}

export const sortOrder = {
  asc: 'ascending',
  desc: 'descending',
}
